import Tilt from 'react-parallax-tilt';
import { motion } from 'framer-motion';
import { styles } from '../styles';
import { github, view } from '../assets';
import { fadeIn, textVariant } from '../utils/motion';
import { projects } from '../constants';
import { SectionWrapper } from '../hoc';

const ProjectCard = ({ index, name, description, tags, image, project_link, source_code_link }) => {
    return (
        <motion.div variants={fadeIn('up', 'spring', index * 0.5, 0.75)}>
            <Tilt
                options={{
                    max: 45,
                    scale: 1,
                    speed: 450
                }}
                className='bg-tertiary p-5 rounded-2xl sm:w-[360px] w-full min-h-[460px]'
            >
                <div className='relative w-full h-[230px]'>
                    <img
                        src={image}
                        alt={name}
                        className='w-full h-full object-cover rounded-2xl'
                    />
                </div>

                <div className='absolute inset-0 flex justify-end m-3 card-img_hover'>
                    <div
                        onClick={() => window.open(source_code_link, '_blank')}
                        className='black-gradient w-10 h-10 rounded-full flex justify-center items-center cursor-pointer'
                    >
                        <img
                            src={github}
                            alt='github'
                            className='w-1/2 h-1/2 object-contain'
                        />
                    </div>
                    <div
                        onClick={() => window.open(project_link, '_blank')}
                        className='black-gradient w-10 h-10 rounded-full flex justify-center items-center cursor-pointer'
                    >
                        <img
                            src={view}
                            alt='view project'
                            className='w-1/2 h-1/2 object-contain filter-white'
                        />
                    </div>
                </div>

                <div className='mt-5'>
                    <h3 className='text-secondary font-bold text-[24px]'>{name}</h3>
                    <p className='mt-2 text-white text-[14px]'>{description}</p>
                </div>

                <div className='mt-4 flex flex-wrap gap-2'>
                    {tags.map((tag) => (
                        <p key={tag.name} className={`text-[14px] ${tag.color}`}>#{tag.name}</p>
                    ))}
                </div>
            </Tilt>
        </motion.div>
    )
}

const Projects = () => {
    return (
        <>
            <motion.div variants={textVariant()}>
                <p className={styles.sectionSubText}>My work</p>
                <h2 className={styles.sectionHeadText}>Projects.</h2>
            </motion.div>

            <div className='w-full flex'>
                <motion.p
                    variants={fadeIn('', '', 0.1, 1)}
                    className='mt-4 text-white text-[17px] max-w-3xl leading-[30px]'
                >
                    The following projects showcase some of my skills and experience. Each project is briefly described and include links to their respective code repository and URL.
                </motion.p>
            </div>

            <div className="mt-12 flex flex-wrap justify-center gap-7">
                {projects.map((project, index) => (
                    <div key={index} className="col-sm-3">
                        <ProjectCard
                            key={`project-${index}`}
                            index={index}
                            {...project}
                        />
                    </div>
                ))}
            </div>
        </>
    )
}

export default SectionWrapper(Projects, 'projects');